<template>
  <b-navbar class="navbar-top" toggleable="lg" :class="{ 'navbar-collapse-show': mainMenuVisible, 'navbar-user-logged-in': isAuthenticated }">
    <div class="navbar-container-lg">
      <b-navbar-brand v-if="logoUrl">
        <b-link class :href="logoUrl">
          <b-img class="brand-logo" :src="brandLogo"/>
        </b-link>
      </b-navbar-brand>
      <b-navbar-brand v-else>
        <router-link class :to="{ name: 'home' }">
            <b-img class="brand-logo" :src="brandLogo"/>
        </router-link>
      </b-navbar-brand>
      <div class="nav-user" v-if="isAuthenticated">
          <span @click="toggleNavbarForceOpen" :title="activeAccount.firstName + ' ' + activeAccount.lastName">
            <font-awesome-icon class="nav-icon" size="2x" icon="user"/>
          </span>
          <span @click="logout()">
            <font-awesome-icon class="nav-icon" size="2x" icon="sign-out-alt"/>
            <span class="sr-only">{{ $t('action_logout') }}</span>
          </span>
      </div>
      <b-navbar-toggle class="nav-user" target="nav-collapse-user" ref="toggle-user" v-else>
        <span>
          <font-awesome-icon class="nav-icon" size="2x" icon="user"/>
        </span>
      </b-navbar-toggle>
      <b-navbar-toggle target="nav-collapse-main" ref="toggle-main">
        <span class="icon-bar top-bar"></span>
        <span class="icon-bar middle-bar"></span>
        <span class="icon-bar bottom-bar"></span>
      </b-navbar-toggle>
      <b-collapse v-model="mainMenuVisible" id="nav-collapse-main" is-nav>
        <ba-mobile-navbar-menu v-if="isAuthenticated" :message-count="messageCount" />
        <div class="container nav-container">
          <ba-navigation/>
        </div>
        <div class="navbar-top-bar">
          <div class="container">
            <ba-language-select/>
            <ba-topbar-menu :message-count="messageCount"/>
          </div>
        </div>
      </b-collapse>
      <b-collapse v-model="userMenuVisible" id="nav-collapse-user" is-nav v-if="!isAuthenticated">
        <ba-login-form class="d-lg-none" context="mobile" id="nav-login-form-mobile" @close="toggleNavbarForceClose"/>
      </b-collapse>
    </div>
  </b-navbar>
</template>

<script>
  import BaTopbarMenu from '@/components/partials/Header/BaTopbarMenu';
  import BaLoginForm from '@/components/partials/Header/BaLoginForm';
  import BaLanguageSelect from '@/components/partials/Header/BaLanguageSelect';
  import BaNavigation from '@/components/partials/Header/BaNavigation';
  import BaMobileNavbarMenu from '@/components/partials/Header/BaMobileNavbarMenu';

  import { mapActions, mapGetters } from 'vuex';

  import Config from '@/services/config';
  import { getThreads } from '@/api/messaging';

  export default {
    components: {
      BaTopbarMenu,
      BaLoginForm,
      BaLanguageSelect,
      BaMobileNavbarMenu,
      BaNavigation,
    },
    data: () => ({
      mainMenuVisible: false,
      userMenuVisible: false,
      messageCount: 0,
    }),
    computed: {
      ...mapGetters(['activeAccount', 'isAuthenticated']),
      logoUrl: function () {
        const routeName = this.$route.name;
        if (routeName === 'home' && Config.logoLink) {
          return Config.logoLink;
        }
        return null;
      },
      brandLogo: function () {
        return Config.projectLogo;
      },
    },
    mounted: function () {
      if (this.isAuthenticated) {
        this.updateMessagesCount();
      }
    },
    methods: {
      ...mapActions(['logout']),
      updateMessagesCount () {
        const self = this;
        getThreads()
          .then(({ data: response }) => {
            this.messageCount = 0;
            response.forEach(function (thread) {
              if (thread.unreadMessages) {
                self.messageCount += thread.unreadMessages;
              }
            });
          })
        ;
      },
      toggleNavbarForceOpen () {
        this.mainMenuVisible = true;
      },
      toggleNavbarForceClose () {
        this.mainMenuVisible = false;
        this.userMenuVisible = false;
      },
    },
    watch: {
      isAuthenticated: function (isAuthenticated) {
        if (isAuthenticated) {
          this.updateMessagesCount();
        }
      },
      $route () {
        if (this.isAuthenticated) {
          this.updateMessagesCount();
        }
      },
      mainMenuVisible: function (mainMenuVisible) {
        if (mainMenuVisible && this.userMenuVisible) {
          this.userMenuVisible = false;
        }
      },
      userMenuVisible: function (userMenuVisible) {
        if (userMenuVisible && this.mainMenuVisible) {
          this.mainMenuVisible = false;
        }
      },
    },
  };
</script>

<style lang="scss">
  @import "../style/_init-vars-functions-mixins";

  .navbar-top {
    display: block;

    @include media-breakpoint-up(lg) {
      padding-top: 40px;
      position: relative;
      &::after {
        content: "";
        display: block;
        position: absolute;
        background: $gray-300;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        z-index: 0;
      }
    }
  }
  .navbar-container-lg {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include media-breakpoint-up(lg) {
      flex-wrap: nowrap;
      justify-content: space-between;
      margin: 0 auto;
      max-width: 960px;
    }
    @include media-breakpoint-up(xl) {
      max-width: 1200px;
    }
  }
  .navbar-brand {
    padding-left: 15px;
    padding-bottom: 21px;
    max-width: 50%;
    width: 255px;
    img {
      width: $logo-width;
      max-width: $logo-max-width;
      height: auto;
    }

    @include media-breakpoint-up(lg) {
      padding: 9px 0;
      img {
        max-height: $logo-max-height-lg;
      }
    }
  }
  .nav-user {
    display: flex;
    flex-wrap: nowrap;
    margin-left: auto;
    margin-right: 23px;
    transition: opacity .2s linear;

    span {
      cursor: pointer;
      display: block;
      margin-left: 26px;
      &:first-child {
        margin-left: 0;
      }
    }
    .nav-icon {
       color: $gray-400;
     }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
  .navbar-collapse {
    @include media-breakpoint-up(lg) {
      padding-top: 0;
      flex: 0 1 auto;
    }
  }
  .navbar-user-logged-in {
    .navbar-collapse {
      margin-top: -90px;
      position: relative;
      z-index: 10;
      @include media-breakpoint-up(lg) {
        margin-top: 0;
        // Needed so top nav can have full width!
        position: static;
      }
    }

    .navbar-brand {
      transition: opacity .2s linear;
    }
    &.navbar-collapse-show {
      .navbar-brand {
        opacity: 0;
      }

        @include media-breakpoint-up(lg) {
        transition: none;
        opacity: 1;
      }
    }
  }
  .navbar-top-bar {
    background-color: $navbar-top-bar-bg;
    font-weight: $ba-font-weight-medium;
    position: relative;
    width: 100%;

    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 0;
      left: 0;
      > .container {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  // Override default navbar-nav styles
  .navbar-light .navbar-top-bar {
    .navbar-nav {
      .nav-link {
        color: $ba-link-color-light;
        transition: color .2s linear;

        &:hover,
        &:focus,
        &.active {
          color: $ba-text-color-light;
        }
      }
      .active > .nav-link {
        color: $ba-text-color-light;
      }
    }
  }
  .nav-container {
    @media (min-width: 360px) {
      padding-left: 45px;
      padding-right: 45px;
    }
    @include media-breakpoint-up(lg) {
      padding: 0;
    }
  }
  .nav-user .fa-2x {
    font-size: 2rem;
  }
</style>

<i18n lang="yaml" locale="de">
  action_logout: 'Logout'
</i18n>
